import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-mb-md" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.pesquisarRedacao,
              class: "row items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_select, {
                  dense: "",
                  label: "Pesquisar por",
                  class: "col-5",
                  modelValue: _ctx.filtro,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filtro) = $event)),
                  options: ['Tipo', 'Disciplina']
                }, null, 8, ["modelValue"]),
                (_ctx.filtro == 'Tipo')
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 0,
                      dense: "",
                      "lazy-rules": "",
                      "emit-value": "",
                      "map-options": "",
                      "stack-label": "",
                      label: "Tipo Redação *",
                      class: "col-5",
                      "option-value": "id",
                      "option-label": "descricao",
                      modelValue: _ctx.filtroTipo,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filtroTipo) = $event)),
                      options: _ctx.tipoRedacoes
                    }, null, 8, ["modelValue", "options"]))
                  : _createCommentVNode("", true),
                (_ctx.filtro == 'Disciplina')
                  ? (_openBlock(), _createBlock(_component_q_select, {
                      key: 1,
                      dense: "",
                      "lazy-rules": "",
                      "emit-value": "",
                      "map-options": "",
                      "stack-label": "",
                      label: "Disciplina *",
                      class: "col-5",
                      "option-value": "id",
                      "option-label": "descricao",
                      modelValue: _ctx.filtroDisciplina,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filtroDisciplina) = $event)),
                      options: _ctx.disciplinas
                    }, null, 8, ["modelValue", "options"]))
                  : _createCommentVNode("", true),
                (_ctx.filtro != '')
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 2,
                      class: "q-ml-sm",
                      padding: "sm",
                      type: "submit",
                      icon: "search",
                      color: "primary"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  class: "q-ml-sm",
                  padding: "sm",
                  color: "red",
                  icon: "undo",
                  to: "/area-redacao"
                })
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_table, {
      title: "Redações",
      "row-key": "id",
      rows: _ctx.redacoes,
      columns: _ctx.columns,
      filter: _ctx.filter,
      loading: _ctx.loading,
      pagination: { rowsPerPage: 10 }
    }, _createSlots({
      top: _withCtx(() => [
        _createVNode(_component_q_space),
        _createVNode(_component_q_input, {
          dense: "",
          debounce: "300",
          color: "primary",
          modelValue: _ctx.filter,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter) = $event))
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "search" })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      "body-cell-created_at": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      _: 2
    }, [
      (_ctx.store.getters.userPerfil == 'administrador')
        ? {
            name: "body-cell-actions",
            fn: _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "primary",
                    "icon-right": "link",
                    onClick: ($event: any) => (_ctx.copyLink(props.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Copiar Link ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "primary",
                    icon: "edit",
                    onClick: ($event: any) => (_ctx.editRedacao(props.row.id)),
                    disable: _ctx.loading
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Editar ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disable"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["rows", "columns", "filter", "loading"])
  ]))
}